/* Shine background start */
.shine-background {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), #2e7d32, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    background-size: 600% 600%;

    -webkit-animation: AnimationName 1.5s ease infinite;
    -moz-animation: AnimationName 1.5s ease infinite;
    -o-animation: AnimationName 1.5s ease infinite;
    animation: AnimationName 1.5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 110% 0%;
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 110% 0%;
    }
}

@-o-keyframes AnimationName {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 110% 0%;
    }
}

@keyframes AnimationName {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 110% 0%;
    }

}

/* Shine background end */