.root {
  align-items: center;
  display: flex;
  height: 32px;
}

.expand {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transform: rotate(0deg);
}

.expand > div {
  align-items: center;
  display: flex;
}

.expand.isOpen {
  transform: rotate(180deg);
}

.handle {
  cursor: grab;
  display: flex;
}

.handle > svg {
  pointer-events: none;
}

.label {
  padding-inline-start: 8px;
  width:100%;
}
