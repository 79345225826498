.app {
  height: 100%;
}

.treeRoot {
  box-sizing: border-box;
  height: 100%;
  padding: 32px;
}

.draggingSource {
  opacity: 0.3;
}

.placeholderContainer {
  position: relative;
}
